import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import axios from 'axios';
import './index.css';
import { store } from './redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Lazy load the Routes component
const MyRoutes = lazy(() => import('./Routes'));

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <ToastContainer />
      <Suspense fallback={
        <div className="fixed inset-0 flex items-center justify-center bg-gray-100 bg-opacity-75 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg flex flex-col items-center">
            <div className="w-16 h-16 border-t-4 border-b-4 border-[#F8D210] rounded-full animate-spin mb-4"></div>
            <h2 className="text-xl font-semibold text-gray-700 mb-2">Loading</h2>
            <p className="text-gray-500 text-sm">Please wait while we prepare your experience...</p>
            <div className="mt-4 w-full bg-gray-200 rounded-full h-1.5">
              <div className="bg-[#F8D210] h-1.5 rounded-full animate-pulse w-3/4"></div>
            </div>
          </div>
        </div>
      }>
        <MyRoutes />
      </Suspense>
    </React.StrictMode>
  </Provider>
);
